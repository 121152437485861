import i18n from 'i18next';
import localization from 'moment/locale/sr';
import moment from 'moment';
import { changeLocalization } from 'core/api/account/account-service';

export const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    language === 'en' ? moment.locale('en') : moment.locale('sr', localization);
    changeLocalization(language);
    if (typeof window !== 'undefined')
      localStorage.setItem('locale', language);
};

export const getCurrentLocale = () => typeof window !== 'undefined' && localStorage.getItem('locale') !== null ? localStorage.getItem('locale') : 'sr';

  