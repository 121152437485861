import * as Sentry from '@sentry/gatsby';
import { ExtraErrorData, HttpClient } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay(),
    new ExtraErrorData(),
    new HttpClient({
      failedRequestStatusCodes: [[500, 505], 507]
    }),
    new BrowserTracing()
  ],
  tracesSampleRate: 0.5,
  sendDefaultPii: true
});
