/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import 'i18n';
import React from 'react';
import { Provider } from 'react-redux';

import SelfnestToastHandler from 'components/toastify';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import store from 'store';

import 'styles/global.scss';
import { FFContextProvider } from '@harnessio/ff-react-client-sdk';
import { Loading } from "./src/components/Loading"

const reactTarget = { name: 'ReactClientSDK', identifier: 'reactclientsdk' }

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <FFContextProvider
      apiKey={process.env.GATSBY_HARNESS_FEATURE_FLAG_API_KEY}
      target={reactTarget}
      fallback={<div><Loading /></div>}
    >
      <SelfnestToastHandler />
      {element}
    </FFContextProvider>
  </Provider>
);
