enum ErrorConstants {
  LOGIN_ACCOUNT_NOT_FOUND = 1000,
  LOGIN_ACCOUNT_WRONG_PASSWORD = 1001,
  LOGIN_ACCOUNT_UNCONFIRMED = 1007,
  REGISTER_EMAIL_ALREADY_EXISTS = 1002,
  REGISTER_AUTHORITY_NOT_FOUND = 1003,
  REGISTER_ACCOUNT_NOT_SAVED = 1004,
  ACTIVATE_ACCOUNT_NOT_FOUND = 1005,
  ACTIVATE_ACCOUNT_NOT_SAVED = 1006,
  ACCOUNT_ALREADY_ACTIVATED = 1007,
  FORGOT_PASSWORD_ACCOUNT_NOT_FOUND = 1008,
  RESET_PASSWORD_ACCOUNT_NOT_FOUND = 1009,
  RESET_PASSWORD_ACCOUT_NOT_SAVED = 1010,
  CHANGE_PASSWORD_ACCOUNT_NOT_FOUND = 1011,
  CHANGE_PASSWORD_NOT_MATCH = 1012,
  CHANGE_PASSWORD_ACCOUNT_NOT_SAVED = 1013,
  EMAIL_NOT_SENT = 1014,
  TOKEN_ACCOUNT_INVALID = 1015,
  INCORRECT_CREDENTIALS = 1025,
  SERVER_API_NOT_AVAILABLE = 1998,
  VERIFY_LINK_INVALID = 2001,
  TIMER = 8000,
  VIDEO_ROOM_ALREADY_EXISTS = 11002,
  APPLE_DISPLAY_NAME_NOT_PROVIDED = 20000
}
export default ErrorConstants;
