import { Spin } from "antd";
import React from "react";

export const Loading = () => (
    <div className="selfnest-loading">
      <div className="selfnest-loading__content">
        <h1>Selfnest</h1>
        <Spin />
      </div>
    </div>
)