exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-reset-password-tsx": () => import("./../../../src/pages/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-auth-reset-password-tsx" */),
  "component---src-pages-chat-index-tsx": () => import("./../../../src/pages/chat/index.tsx" /* webpackChunkName: "component---src-pages-chat-index-tsx" */),
  "component---src-pages-check-email-tsx": () => import("./../../../src/pages/check-email.tsx" /* webpackChunkName: "component---src-pages-check-email-tsx" */),
  "component---src-pages-forgot-password-sent-tsx": () => import("./../../../src/pages/forgot-password-sent.tsx" /* webpackChunkName: "component---src-pages-forgot-password-sent-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-messages-tsx": () => import("./../../../src/pages/messages.tsx" /* webpackChunkName: "component---src-pages-messages-tsx" */),
  "component---src-pages-my-therapies-index-tsx": () => import("./../../../src/pages/my-therapies/index.tsx" /* webpackChunkName: "component---src-pages-my-therapies-index-tsx" */),
  "component---src-pages-my-therapies-schedule-therapy-tsx": () => import("./../../../src/pages/my-therapies/schedule-therapy.tsx" /* webpackChunkName: "component---src-pages-my-therapies-schedule-therapy-tsx" */),
  "component---src-pages-payment-failed-index-tsx": () => import("./../../../src/pages/payment-failed/index.tsx" /* webpackChunkName: "component---src-pages-payment-failed-index-tsx" */),
  "component---src-pages-payment-pending-index-tsx": () => import("./../../../src/pages/payment-pending/index.tsx" /* webpackChunkName: "component---src-pages-payment-pending-index-tsx" */),
  "component---src-pages-payment-succeed-index-tsx": () => import("./../../../src/pages/payment-succeed/index.tsx" /* webpackChunkName: "component---src-pages-payment-succeed-index-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/terms-of-use/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */),
  "component---src-pages-test-video-chat-tsx": () => import("./../../../src/pages/test-video-chat.tsx" /* webpackChunkName: "component---src-pages-test-video-chat-tsx" */),
  "component---src-pages-video-chat-tsx": () => import("./../../../src/pages/video-chat.tsx" /* webpackChunkName: "component---src-pages-video-chat-tsx" */)
}

