import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from 'store';

import { clearError } from 'store/error/actions';
import ErrorConstants from 'util/constants/error.constants';

export const successToastMessage = (
  message: string,
  hideProgressBar?: boolean
) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: hideProgressBar !== undefined ? hideProgressBar : true
  });

  return toast;
};

export const errorToastMessage = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true
  });
};

const SelfnestToastHandler = () => {
  const errorCode = useSelector((root: RootState) => root.error.errorCode);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (errorCode) {
      getMessage();
      setTimeout(() => dispatch(clearError()), ErrorConstants.TIMER);
    }
  }, [errorCode]);

  const showErrorMessage = (text: string) => {
    toast.error(t(text), {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true
    });
  };

  function getMessage() {
    switch (errorCode) {
      case ErrorConstants.INCORRECT_CREDENTIALS:
        showErrorMessage('login.errors.server.loginAccountWrongPassword');
        break;
      case ErrorConstants.REGISTER_EMAIL_ALREADY_EXISTS:
        showErrorMessage('register.errors.server.emailAlreadyExists');
        break;
      case ErrorConstants.SERVER_API_NOT_AVAILABLE:
        showErrorMessage('server.errors.unavailable');
        break;
      case ErrorConstants.LOGIN_ACCOUNT_UNCONFIRMED:
        showErrorMessage('login.errors.server.loginAccountMailUnconfirmed');
        break;
      case ErrorConstants.VERIFY_LINK_INVALID:
        showErrorMessage('register.errors.server.verificationLink');
        break;
      case ErrorConstants.APPLE_DISPLAY_NAME_NOT_PROVIDED:
        showErrorMessage('register.errors.server.appleDisplayNameNotProvided');
      default:
        showErrorMessage('errors.unknown');
    }
  }
  return (
    <ToastContainer
      style={{ fontSize: '16px' }}
      autoClose={ErrorConstants.TIMER}
    />
  );
};

export default SelfnestToastHandler;
