import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { accountReducer } from './account/reducer';
import { cameraReducer } from './camera/reducer';
import { chatReducer } from './chat/reducer';
import { errorReducer } from './error/reducer';
import { registerReducer } from './register/reducer';
import { scheduleTherapyReducer } from './schedule-therapy/reducer';
import { spinnerReducer } from './spinner/reducer';
import { layoutReducer } from './layout/reducer';

const rootReducer = combineReducers({
  account: accountReducer,
  chat: chatReducer,
  scheduleTherapy: scheduleTherapyReducer,
  error: errorReducer,
  spinner: spinnerReducer,
  camera: cameraReducer,
  register: registerReducer,
  layout: layoutReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

const store = createStore(rootReducer, composedEnhancer);
export default store;
