interface CameraOnState {
  isRemoteCameraOn: boolean;
}

export type SetCameraOnAction = {
  type: 'setRemoteCameraOn';
  payload: boolean;
};

type CameraOnAction = SetCameraOnAction;

const initalState: CameraOnState = {
  isRemoteCameraOn: false
};

export const cameraReducer = (state = initalState, action: CameraOnAction) => {
  switch (action.type) {
    case 'setRemoteCameraOn':
      return { ...state, isRemoteCameraOn: action.payload };
    default:
      return state;
  }
};
