import { AccountResponseDTO } from 'core/api/account/payload/account-service.dto';
import { ChangeEmailPreferences } from '../../models/authDetails';

export const LOGIN = 'account/login';
export const LOGOUT = 'account/logout';
export const CHANGE_EMAIL_PREFERENCES = 'account/changeEmailPreferences';
export const INCOMPLETE_REGISTRATION = 'account/incompleteRegistration';

export interface AccountState {
  token: string;
  account: AccountResponseDTO;
}

export interface AccountReducerState {
  account: AccountState | null;
  errorCode: number | null;
}

const initialState: AccountReducerState = { account: null, errorCode: null };

export function accountReducer(
  state = initialState,
  action: AccountActionTypes
): AccountReducerState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        errorCode: null,
        account: action.payload
      };
    case LOGOUT:
      return {
        ...state,
        account: null
      };
    case CHANGE_EMAIL_PREFERENCES:
      return {
        ...state,
        account: {
          ...state.account!,
          account: {
            ...state.account!.account,
            sendEmailNotifications: action.payload.sendNotifications
          }
        }
      };
    case INCOMPLETE_REGISTRATION:
      return {
        ...state,
        errorCode: null,
        account: action.payload
      };
    default:
      return state;
  }
}

interface LoginAction {
  type: typeof LOGIN;
  payload: AccountState;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface ChangeEmailPreferencesAction {
  type: typeof CHANGE_EMAIL_PREFERENCES;
  payload: ChangeEmailPreferences;
}

interface IncompleteRegistrationAction {
  type: typeof INCOMPLETE_REGISTRATION;
  payload: AccountState;
}

export type AccountActionTypes =
  | LoginAction
  | LogoutAction
  | ChangeEmailPreferencesAction
  | IncompleteRegistrationAction;
