export interface AdditionalAnswers {
  relationship: {
    partner: string;
    child: string;
    professional: string;
  };
  skills: {
    confidence: string;
    communication: string;
    assertiveness: string;
  };
}

export interface TherapyType {
  name: string;
  uuid: number;
}

export interface TherapySkills {
  name: string;
  uuid: number;
}

export interface TherapistSex {
  name: string;
  uuid: number;
}

export enum CategoryType {
  Problems = 'Problems',
  Skills = 'Skills',
  Relationships = 'Relationships'
}

export type Categories = Record<CategoryType, Feelings[]>;

export interface Feelings {
  name: string;
  uuid: number;
}

export interface Questionnaire {
  isAnonymous: boolean;
  additionalAnswers: string;
  additionalNotes: string;
  categoryUuids: string[];
  therapistSex: string;
  therapyType: string;
}
