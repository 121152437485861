export const CHANGE_OPEN = 'layout/changeOpen';

export interface LayoutState {
  open: boolean;
}

const initialState: LayoutState = {
  open: false
};

export function layoutReducer(state = initialState, action: LayoutActionTypes) {
  switch (action.type) {
    case CHANGE_OPEN:
      return {
        ...state,
        open: action.payload ?? !state.open
      };
    default:
      return state;
  }
}

interface ChangeOpenAction {
  type: typeof CHANGE_OPEN;
  payload?: boolean;
}

export type LayoutActionTypes = ChangeOpenAction;
