export const INCREMENT_STEP = 'register/incrementStep';
export const RESET = 'register/reset';

export enum RegisterStep {
  START,
  FINISH
}

export interface RegisterState {
  step: RegisterStep;
  googleApiToken?: string;
  apiToken?: string;
  provider?: 'google' | 'apple' | '';
  firebaseIdToken?: string;
}

const initialState: RegisterState = {
  step: RegisterStep.START,
  googleApiToken: '',
  apiToken: '',
  provider: '',
  firebaseIdToken: ''
};

export function registerReducer(
  state = initialState,
  action: RegisterActionTypes
) {
  switch (action.type) {
    case INCREMENT_STEP:
      return {
        ...state,
        ...action.payload
      };
    case RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

interface IncrementStepAction {
  type: typeof INCREMENT_STEP;
  payload: RegisterState;
}

interface ResetAction {
  type: typeof RESET;
}

export type RegisterActionTypes = IncrementStepAction | ResetAction;
