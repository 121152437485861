interface SpinnerState {
    isLoading: boolean;
}

export type SetLoadingAction = {
    type: 'setLoading',
    payload: boolean
}

type SpinnerAction = SetLoadingAction;

const initalState: SpinnerState = {
    isLoading: false
}

export const spinnerReducer = (state = initalState, action: SpinnerAction) => {
    switch (action.type) {
        case "setLoading":
            return { ...state, isLoading: action.payload }
        default:
            return state;
    }
};
