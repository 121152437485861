import { AxiosResponse } from 'axios';
import httpService from '../httpService';

export const changeLocalization = (
  localization: string
): Promise<AxiosResponse> =>
  httpService.post(`auth/localization/${localization}`);

export const getByEmail = (email: string): Promise<AxiosResponse> =>
  httpService.post(`auth/email/${email}`);

export const getEmailPreferences = (): Promise<AxiosResponse> =>
  httpService.get('auth/email-preferences');
