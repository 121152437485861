import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from 'store';
import { LOGOUT } from 'store/account/reducer';
import { LocalStorageFields } from '../../components/constants/localStorage.const';

const instance = axios.create({
  baseURL: process.env.GATSBY_API_URL
});

const refreshAuthLogic = (failedRequest: any) => {
  if (!failedRequest.config.url?.includes('auth')) {
    instance
      .get('/auth/refresh-token', { withCredentials: true })
      .then((response) => {
        const { accessToken } = response.data;
        failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
        const accountState = JSON.parse(
          localStorage.getItem(LocalStorageFields.AccountState) ?? ''
        );
        accountState.token = accessToken;
        localStorage.setItem(
          LocalStorageFields.AccountState,
          JSON.stringify(accountState)
        );
        return Promise.resolve();
      })
      .catch((error) => {
        localStorage.removeItem(LocalStorageFields.AccountState);
        store.dispatch({
          type: LOGOUT
        });
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
  }
  return Promise.resolve();
};

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  statusCodes: [401]
});

instance.interceptors.request.use((config) => {
  if (!config.url?.includes('login' || config.url?.includes('register'))) {
    try {
      const { token } = JSON.parse(
        localStorage.getItem(LocalStorageFields.AccountState) ?? ''
      );
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    } catch (e) {
      console.log(e);
    }
  }
  return config;
});

export default instance;
