import { BillingPlanResponseDTO } from 'core/api/billing/payload/billing-service.response';
import { AvailableAppointment, Therapist } from 'models/therapist';
import {
  Categories,
  CategoryType,
  Feelings,
  TherapistSex,
  TherapySkills,
  TherapyType
} from '../../models/questionnaire';

export const INCREMENT_STEP = 'scheduleTherapy/incrementStep';
export const DECREMENT_STEP = 'scheduleTherapy/decrementStep';
export const SET_THERAPIST = 'scheduleTherapy/setTherapist';
export const SET_PRICE_PACKAGE = 'scheduleTherapy/setPricePackage';
export const SET_SCHEDULE_DATE_TIME = 'scheduleTherapy/setScheduleDateTime';
export const SET_FEELINGS = 'scheduleTherapy/SetFeelings';
export const SET_SELECTED_FEELINGS = 'scheduleTherapy/setSelectedFeelings';
export const SET_SELECTED_GENDER = 'scheduleTherapy/setSelectedGender';
export const SET_SELECTED_SKILLS = 'scheduleTherapy/setSelectedSkills';
export const SET_SELECTED_TYPE = 'scheduleTherapy/setSelectedType';
export const SET_SUGGESTED_THERAPISTS =
  'scheduleTherapy/setSuggestedTherapists';
export const SET_IS_ANONYMOUS = 'scheduleTherapy/setIsAnonymous';
export const SET_QUESTIONNAIRE_UUID = 'scheduleTherapy/setQuestionnaireUuid';
export const SET_SELECTED_APPOINTMENT =
  'scheduleTherapy/setSelectedAppointment';
export const SET_THERAPY_INTENT_UUID = 'scheduleTherapy/setTherapyIntentUuid';
export const RESET_STATE = 'scheduleTherapy/resetState';
export const SET_STEP = 'scheduleTherapy/setStep';
export const SET_SCHEDULING_TYPE = 'scheduleTherapy/setSchedulingType';
export const SET_ADDITIONAL_NOTES = 'scheduleTherapy/aditionalNotes';
export const SET_COUPON = 'scheduleTherapy/coupon';

export interface CouponState {
  coupon: string;
  valid: '' | 'error' | 'success' | 'validating';
  newPrice: number | null;
}

export enum ScheduleTherapyStep {
  InitialStep,
  Therapy,
  Package,
  Questionnaire,
  Loading,
  Appointment,
  Review,
  Payment,
  PendingAppointment
}
export interface ScheduleTherapyState {
  step: ScheduleTherapyStep;
  selectedTherapist: Therapist | null;
  selectedBillingPlan: BillingPlanResponseDTO | null;
  selectedDateTime: string | null;
  selectedFeelings: Feelings[];
  selectedAppointment: AvailableAppointment | null;
  isAnonymous: boolean;
  therapyIntentUuid: string | null;
  questionnaireUuid: string | null;
  feelings: Categories;
  selectedGender: TherapistSex[];
  selectedSkills: Feelings[];
  selectedType: Feelings[];
  suggestedTherapists: Therapist[];
  schedulingType: string;
  additionalNotes: string;
  couponState: CouponState;
}

const initialState: ScheduleTherapyState = {
  step: 0,
  selectedTherapist: null,
  selectedBillingPlan: null,
  selectedDateTime: null,
  isAnonymous: false,
  questionnaireUuid: null,
  selectedAppointment: null,
  therapyIntentUuid: null,
  selectedFeelings: [],
  feelings: {
    [CategoryType.Problems]: [],
    [CategoryType.Skills]: [],
    [CategoryType.Relationships]: []
  },
  selectedGender: [],
  selectedSkills: [],
  selectedType: [],
  suggestedTherapists: [],
  schedulingType: '',
  additionalNotes: '',
  couponState: {
    coupon: '',
    newPrice: null,
    valid: ''
  }
};

export function scheduleTherapyReducer(
  state = initialState,
  action: ScheduleTherapyActionTypes
): ScheduleTherapyState {
  switch (action.type) {
    case INCREMENT_STEP:
      return {
        ...state,
        step: state.step + 1
      };
    case DECREMENT_STEP:
      let decrementStep = 1;
      if (state.step === ScheduleTherapyStep.Appointment) {
        decrementStep = 2;
      }
      return {
        ...state,
        step: state.step - decrementStep
      };
    case SET_SCHEDULING_TYPE:
      return {
        ...state,
        schedulingType: action.payload
      };
    case SET_THERAPIST:
      return {
        ...state,
        selectedTherapist: action.payload
      };
    case SET_SCHEDULE_DATE_TIME:
      return {
        ...state,
        selectedDateTime: action.payload
      };
    case SET_PRICE_PACKAGE:
      return {
        ...state,
        selectedBillingPlan: action.payload
      };

    case SET_FEELINGS:
      return {
        ...state,
        feelings: action.payload
      };
    case SET_SELECTED_FEELINGS:
      return {
        ...state,
        selectedFeelings: action.payload
      };
    case SET_SELECTED_GENDER:
      return {
        ...state,
        selectedGender: action.payload
      };
    case SET_SELECTED_SKILLS:
      return {
        ...state,
        selectedSkills: action.payload
      };
    case SET_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.payload
      };
    case SET_SUGGESTED_THERAPISTS:
      return {
        ...state,
        suggestedTherapists: action.payload
      };
    case SET_IS_ANONYMOUS:
      return {
        ...state,
        isAnonymous: action.payload
      };
    case SET_QUESTIONNAIRE_UUID:
      return {
        ...state,
        questionnaireUuid: action.payload
      };
    case SET_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.payload
      };
    case SET_THERAPY_INTENT_UUID:
      return {
        ...state,
        therapyIntentUuid: action.payload
      };
    case SET_ADDITIONAL_NOTES:
      return {
        ...state,
        additionalNotes: action.payload
      };
    case RESET_STATE:
      return { ...initialState };
    case SET_STEP:
      return {
        ...state,
        step: action.payload
      };
    case SET_COUPON:
      return {
        ...state,
        couponState: {
          ...state.couponState,
          ...action.payload
        }
      };
    default:
      return state;
  }
}

interface IncrementStepAction {
  type: typeof INCREMENT_STEP;
}

interface SetStepAction {
  type: typeof SET_STEP;
  payload: number;
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface DecrementStepAction {
  type: typeof DECREMENT_STEP;
}

interface SetSelectedAppointment {
  type: typeof SET_SELECTED_APPOINTMENT;
  payload: AvailableAppointment;
}

interface SetSchedulingTypeAction {
  type: typeof SET_SCHEDULING_TYPE;
  payload: string;
}

interface SetTHerapistAction {
  type: typeof SET_THERAPIST;
}

interface SetPricePackageAction {
  type: typeof SET_PRICE_PACKAGE;
}

interface SetScheduleDateTimeAction {
  type: typeof SET_SCHEDULE_DATE_TIME;
  payload: string;
}

interface SetQuestionnaireUuidAction {
  type: typeof SET_QUESTIONNAIRE_UUID;
  payload: string;
}

interface SetTherapyIntentUuidAction {
  type: typeof SET_THERAPY_INTENT_UUID;
  payload: string;
}
interface SetScheduleDateTimeAction {
  type: typeof SET_SCHEDULE_DATE_TIME;
  payload: Date;
}

interface SetIsAnonymousAction {
  type: typeof SET_IS_ANONYMOUS;
  payload: boolean;
}

interface SetFeelings {
  type: typeof SET_FEELINGS;
  payload: Feelings[];
}

interface SetSelectedFeelings {
  type: typeof SET_SELECTED_FEELINGS;
  payload: Feelings[];
}

interface SetSelectedGender {
  type: typeof SET_SELECTED_GENDER;
  payload: TherapistSex[];
}

interface SetSelectedSkills {
  type: typeof SET_SELECTED_SKILLS;
  payload: TherapySkills[];
}

interface SetSelectedType {
  type: typeof SET_SELECTED_TYPE;
  payload: TherapyType[];
}

interface SetSuggestedTherapists {
  type: typeof SET_SUGGESTED_THERAPISTS;
  payload: Therapist[];
}

interface SetAdditionalNotes {
  type: typeof SET_ADDITIONAL_NOTES;
  payload: string;
}

interface SetCoupon {
  type: typeof SET_COUPON;
  payload: Partial<CouponState>;
}

export type ScheduleTherapyActionTypes =
  | IncrementStepAction
  | DecrementStepAction
  | SetTHerapistAction
  | SetPricePackageAction
  | SetScheduleDateTimeAction
  | SetFeelings
  | SetSelectedFeelings
  | SetSelectedGender
  | SetSelectedSkills
  | SetSelectedType
  | SetAdditionalNotes
  | SetSuggestedTherapists
  | SetIsAnonymousAction
  | SetQuestionnaireUuidAction
  | SetTherapyIntentUuidAction
  | ResetStateAction
  | SetSelectedAppointment
  | SetStepAction
  | SetSchedulingTypeAction
  | SetCoupon;
