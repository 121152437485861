import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getCurrentLocale } from 'util/helpers/language.helpers.ts';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'translation',
    lng: getCurrentLocale(),
    fallbackLng: ['en', 'sr'],
    debug: false,
    react: {
      wait: true,
      useSuspense: false
    }
  });

export default i18n;
