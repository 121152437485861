import type { ChatResponseDTO } from 'core/api/chat/payload/chat-service.dto';
import {
  Client,
  Conversation,
  Message,
  RestPaginator
} from '@twilio/conversations';

export const SET_PUBLIC_CLIENT = 'chat/set_public_client';
export const SET_ANONYMOUS_CLIENT = 'chat/set_anonymous_client';
export const SET_ERROR = 'chat/set_error';
export const SET_REDUX_CONVERSATIONS = 'chat/set_redux_conversations';
export const SELECT_REDUX_CONVERSATION = 'chat/select_redux_conversation';
export const RESET_CHAT_STATE = 'chat/reset_chat_state';

type LoadingState = 'loading' | 'complete' | 'error';

export interface ReduxConversation {
  details: ChatResponseDTO;
  currentPage: RestPaginator<Message>;
  conversation: Conversation;
}

export interface ChatState {
  publicClient?: Client;
  anonymousClient?: Client;
  loadingState: LoadingState;
  error?: Error;
  reduxConversations?: ReduxConversation[];
  selectedReduxConversation?: ReduxConversation;
}

const initialState: ChatState = {
  publicClient: undefined,
  anonymousClient: undefined,
  loadingState: 'loading',
  error: undefined,
  reduxConversations: undefined,
  selectedReduxConversation: undefined
};

export function chatReducer(
  state = initialState,
  action: ChatActionTypes
): ChatState {
  switch (action.type) {
    case SET_PUBLIC_CLIENT:
      return {
        ...state,
        publicClient: action.payload.client
      };
    case SET_ANONYMOUS_CLIENT:
      return {
        ...state,
        anonymousClient: action.payload.client
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loadingState: 'error'
      };
    case SET_REDUX_CONVERSATIONS:
      return {
        ...state,
        reduxConversations: action.payload.reduxConversations
      };
    case SELECT_REDUX_CONVERSATION:
      return {
        ...state,
        selectedReduxConversation: action.payload.reduxConversation
      };
    case RESET_CHAT_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

interface SetPublicClientAction {
  type: typeof SET_PUBLIC_CLIENT;
  payload: { client: Client };
}

interface SetAnonymousClientAction {
  type: typeof SET_ANONYMOUS_CLIENT;
  payload: { client: Client };
}

interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: { error: Error };
}

interface SetReduxConversationsAction {
  type: typeof SET_REDUX_CONVERSATIONS;
  payload: { reduxConversations: ReduxConversation[] };
}

interface SelectReduxConversationAction {
  type: typeof SELECT_REDUX_CONVERSATION;
  payload: { reduxConversation: ReduxConversation };
}

interface ResetChatStateAction {
  type: typeof RESET_CHAT_STATE;
}

export type ChatActionTypes =
  | SetPublicClientAction
  | SetAnonymousClientAction
  | SetErrorAction
  | SetReduxConversationsAction
  | SelectReduxConversationAction
  | ResetChatStateAction;
